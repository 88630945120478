import { OrderStatusesEnum } from 'src/shared/constants'

export enum BankCardFormEntities {
  cardNumber = 'cardNumber',
  expDate = 'expDate',
  cvc = 'cvc',
  cardHolderName = 'cardHolderName',
  saveData = 'saveData',
  sendReceipt = 'sendReceipt',
  email = 'email',
}

export type BankCardFromData = {
  [BankCardFormEntities.cardNumber]: string
  [BankCardFormEntities.expDate]: string
  [BankCardFormEntities.cvc]: string
  [BankCardFormEntities.cardHolderName]: string
  [BankCardFormEntities.email]: string
  [BankCardFormEntities.saveData]?: boolean
  [BankCardFormEntities.sendReceipt]?: boolean
}

export type BankCardDepositReqData =
  | {
      pan: string
      cardholder: string
      expiration: string
      cvv: string
      remember: boolean
    }
  | {
      id: string
      cvv: string
    }

export type BankCardDepositRes = {
  id: string
  amount: number
  currency: string
  transactionId: string
  status: OrderStatusesEnum
  redirectUrl?: string
}
