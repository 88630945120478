import axios, { AxiosError } from 'axios'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
// local libs
import { api, userSession } from 'src/app/config/api'
import { PagesEnum } from 'src/app/routes'
import { OrderStatusesEnum } from 'src/shared/constants'
import { CARD_METHODS } from 'src/shared/constants/cardMethods'
import { ResponseStatusEnum } from 'src/shared/types/enums'
import { checkOrderStatus } from 'src/shared/utils'
// types
import { BankCardDepositReqData, BankCardDepositRes } from '../types'

export const useBankCardDeposit = () => {
  const navigate = useNavigate()
  const [isProceeding, setIsProceeding] = useState(false)

  const controlOrderStatus = (id: string) => {
    checkOrderStatus(id)
      .then((res) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const resType = res.data.type
        const transactionData = res.data.data
        if (!transactionData || resType !== ResponseStatusEnum.DATA) {
          setIsProceeding(false)
          navigate(PagesEnum.contactStore)
          return
        }

        const { redirectUrl, status } = transactionData

        if (redirectUrl) {
          window.location.href = redirectUrl
        } else if (status === OrderStatusesEnum.COMPLETED) {
          setIsProceeding(false)
          navigate(PagesEnum.success)
        } else if (
          status === OrderStatusesEnum.ERROR ||
          status === OrderStatusesEnum.DECLINED ||
          status === OrderStatusesEnum.CANCELED
        ) {
          setIsProceeding(false)
          navigate(PagesEnum.error)
        } else {
          setTimeout(controlOrderStatus, 2_000, id)
        }
      })
      .catch((err: AxiosError<ResponseMessage>) => {
        console.error('Deposit error:', err)
        navigate(PagesEnum.error)
      })
  }

  const makeBankCardDeposit = (cardInfo: BankCardDepositReqData) => {
    setIsProceeding(true)
    axios
      .request<ResponseWithData<BankCardDepositRes>>({
        url: api.deposit,
        method: 'POST',
        data: {
          cardInfo,
          paymentMethod: CARD_METHODS.bankcard,
          hash: userSession,
        },
      })
      .then((res) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const resType = res.data.type
        const transactionData = res.data.data
        if (!transactionData || resType !== ResponseStatusEnum.DATA) {
          setIsProceeding(false)
          navigate(PagesEnum.contactStore)
          return
        }

        const { status, redirectUrl, id } = transactionData

        if (status === OrderStatusesEnum.COMPLETED) {
          setIsProceeding(false)
          navigate(PagesEnum.success)
        } else if (status === OrderStatusesEnum.IN_PROGRESS && redirectUrl) {
          setIsProceeding(false)
          navigate(redirectUrl)
        } else if (status === OrderStatusesEnum.IN_PROGRESS && !redirectUrl) {
          controlOrderStatus(id)
        } else if (status === OrderStatusesEnum.ERROR) {
          setIsProceeding(false)
          navigate(PagesEnum.error)
        } else {
          setIsProceeding(false)
          navigate(PagesEnum.contactStore)
        }
      })
      .catch((err: AxiosError<ResponseMessage>) => {
        console.error('Deposit error:', err)
        navigate(PagesEnum.error)
      })
  }

  return { isProceeding, makeBankCardDeposit }
}
