import axios, { AxiosError } from 'axios'
// local libs
import { api } from 'src/app/config/api'
import { store } from '../store'
import { DataContextValues } from '../types'

type Data = DataContextValues['paymentData']
type Response = ResponseWithData<Data>

export const requestPaymentData = async () => {
  return axios
    .get<Response>(api.paymentData)
    .then((res) => {
      const resData = res.data

      if (resData.data) {
        store.paymentData.value = resData.data

        return Promise.resolve(resData.data)
      }

      throw Error('No data')
    })
    .catch((err: AxiosError<ResponseMessage>) => {
      console.error("Can't load payment data:", err)

      return Promise.reject()
    })
}
