import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { Button, CardPaySystemIcon } from 'src/shared/ui'
// local libs
import { bankCardResolver } from '../resolver'
import { Checkbox } from 'src/shared/ui/Checkbox'
import {
  CardCodeInput,
  CardExpirationDateInput,
  CardHolderInput,
  CardNumberInput,
  EmailInput,
} from 'src/shared/ui/Input'
import { useTranslate } from 'src/shared/hooks/useTranslate'
import { store } from 'src/app/store'
import { parseCardType } from 'src/shared/utils'
// types
import { BankCardFormEntities, BankCardFromData } from '../types'

import styles from '../BankCardMethod.module.scss'
import { useBankCardDeposit } from './utils'

export const RegularForm: FC = () => {
  const { translate } = useTranslate()
  const { isProceeding, makeBankCardDeposit } = useBankCardDeposit()

  const defaultEmail = store.paymentData.value?.userInfo.email

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    watch,
  } = useForm<BankCardFromData>({
    resolver: bankCardResolver,
    defaultValues: {
      [BankCardFormEntities.email]: defaultEmail,
    },
  })

  const { cardNumber } = watch()

  const onSubmit = (formVals: BankCardFromData) =>
    makeBankCardDeposit({
      pan: formVals[BankCardFormEntities.cardNumber],
      cardholder: formVals[BankCardFormEntities.cardHolderName],
      expiration: formVals[BankCardFormEntities.expDate],
      cvv: formVals[BankCardFormEntities.cvc],
      remember: formVals[BankCardFormEntities.saveData] || false,
    })

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={styles.bankCardMethodContainer}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={styles.row}>
        <CardNumberInput
          id={BankCardFormEntities.cardNumber}
          control={control}
          error={errors[BankCardFormEntities.cardNumber]?.message}
          label={translate('Bank Card Method.fieldCardNumberLabel')}
          placeholder={translate('Bank Card Method.fieldCardNumberPlaceholder')}
        />
        {cardNumber?.length > 1 && (
          <CardPaySystemIcon
            cardType={parseCardType(cardNumber)}
            className={styles.cardIcon}
          />
        )}
      </div>
      <div className={styles.row}>
        <CardExpirationDateInput
          id={BankCardFormEntities.expDate}
          control={control}
          error={errors[BankCardFormEntities.expDate]?.message}
          label={translate('Bank Card Method.fieldExpiryDateLabel')}
          placeholder={translate('Bank Card Method.fieldExpiryDatePlaceholder')}
        />
        <CardCodeInput
          id={BankCardFormEntities.cvc}
          control={control}
          error={errors[BankCardFormEntities.cvc]?.message}
          label={translate('Bank Card Method.fieldCVCLabel')}
          placeholder={translate('Bank Card Method.fieldCVCPlaceholder')}
        />
      </div>
      <div className={styles.row}>
        <CardHolderInput
          id={BankCardFormEntities.cardHolderName}
          control={control}
          error={errors[BankCardFormEntities.cardHolderName]?.message}
          label={translate('Bank Card Method.fieldCardHolderLabel')}
          placeholder={translate('Bank Card Method.fieldCardHolderPlaceholder')}
        />
      </div>
      <div className={styles.row}>
        <EmailInput
          id={BankCardFormEntities.email}
          control={control}
          error={errors[BankCardFormEntities.email]?.message}
          label={translate('Payment Method Common.fieldEmailLabel')}
          placeholder={translate('Payment Method Common.fieldEmailPlaceholder')}
        />
      </div>
      <div className={styles.row}></div>
      <div className={styles.row}>
        <Checkbox
          id={BankCardFormEntities.saveData}
          control={control}
          label={translate('Payment Method Common.checkboxSaveDataLabel')}
        />
      </div>
      <div className={styles.row}></div>
      <Button size="stretch" disabled={isSubmitting || isProceeding}>
        {translate('Bank Card Method.submitButton', {
          amount: `${store.paymentData.value?.paymentInfo.amount} ${store.paymentData.value?.paymentInfo.currency}`,
        })}
      </Button>
    </form>
  )
}
