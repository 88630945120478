import axios from 'axios'
import { api, userSession } from 'src/app/config/api'
import { OrderStatusesEnum } from '../constants'

type OrderData = {
  status: OrderStatusesEnum
  id: string
  amount: number
  currency: string
  confirmationType: string | null
  redirectUrl: string | null
}

export const checkOrderStatus = (id: string) => {
  if (!userSession) return Promise.reject()
  const query = new URLSearchParams({
    transactionId: id,
    hash: userSession,
  }).toString()
  return axios.request<ResponseWithData<OrderData>>({
    url: `${api.transactionStatus}?${query}`,
  })
}
