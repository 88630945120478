import { FC } from 'react'
// local libs
import { useTranslate } from 'src/shared/hooks/useTranslate'
import { store } from 'src/app/store'
import { Button, PaymentMethodCard } from 'src/shared/ui'
// styles
import styles from '../P2PMethod.module.scss'
import {
  MethodNameEnum,
  PaymentMethodProps,
} from 'src/app/Layout/PaymentMethods/types'

export const P2PMethod: FC<PaymentMethodProps> = ({ disabled }) => {
  const { translate } = useTranslate()

  return (
    <PaymentMethodCard
      id={MethodNameEnum.P2PWithoutBank}
      title={translate('P2P Method.title')}
      caption={translate('P2P Method.caption')}
      iconLink="/payment-p2p-fallback-icon.svg"
      iconWrapperColor="var(--paymentMethodCard-p2pMethodColor)"
      disabled={disabled}
    >
      <div className={styles.p2pMethodContainer}>
        <Button
          size="stretch"
          onClick={() => {
            store.isPaymentMethodsDisabled.value = true
          }}
        >
          {translate('P2P Method.payButton', {
            amount: `${store.paymentData.value?.paymentInfo.amount} ${store.paymentData.value?.paymentInfo.currency}`,
          })}
        </Button>
      </div>
    </PaymentMethodCard>
  )
}
