import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
// local libs
import { useTranslate } from 'src/shared/hooks/useTranslate'
import { Button, PaymentMethodCard, Text } from 'src/shared/ui'
import { TextKind } from 'src/shared/ui/Text'
import { PhoneInput } from 'src/shared/ui/Input/specificInputs/PhoneInput'
import { store } from 'src/app/store'
import { sberPayResolver } from './resolver'
// styles
import styles from './SberPayMethod.module.scss'
// types
import { SberPayEntities, SberPayFromData } from './types'
import {
  MethodNameEnum,
  PaymentMethodProps,
} from 'src/app/Layout/PaymentMethods/types'

export const SberPayMethod: FC<PaymentMethodProps> = ({ disabled }) => {
  const { translate } = useTranslate()
  const [currStep, setCurrStep] = useState<'init' | 'phone'>('init')

  const {
    handleSubmit: handlePhoneStepSubmit,
    control: phoneStepControl,
    formState: { errors: phoneStepErrors },
  } = useForm<SberPayFromData>({
    resolver: sberPayResolver,
  })

  const currStepForm = {
    init: (
      <form
        onSubmit={(e) => {
          e.preventDefault()
          store.isPaymentMethodsDisabled.value = true
          setCurrStep('phone')
        }}
        onClick={(e) => e.stopPropagation()}
        className={styles.form}
      >
        <Button size="stretch">
          {translate('SberPay Method.submitButton', {
            amount: `${store.paymentData.value?.paymentInfo.amount} ${store.paymentData.value?.paymentInfo.currency}`,
          })}
        </Button>
      </form>
    ),
    phone: (
      <form
        // eslint-disable-next-line no-console
        onSubmit={handlePhoneStepSubmit((x) => console.log(x))}
        onClick={(e) => e.stopPropagation()}
        className={styles.form}
      >
        <Text kind={TextKind.title} className={styles.title}>
          {translate('SberPay Method.subtitle')}
        </Text>
        <PhoneInput
          id={SberPayEntities.phone}
          control={phoneStepControl}
          label={translate('SberPay Method.fieldPhoneLabel')}
          placeholder={translate('SberPay Method.fieldPhonePlaceholder')}
          error={phoneStepErrors[SberPayEntities.phone]?.message}
        />
        <Button size="stretch">
          {translate('Bank Card Method.submitButton', {
            amount: `${store.paymentData.value?.paymentInfo.amount} ${store.paymentData.value?.paymentInfo.currency}`,
          })}
        </Button>
      </form>
    ),
  }

  return (
    <PaymentMethodCard
      id={MethodNameEnum.SberPay}
      title={translate('SberPay Method.title')}
      caption={translate('SberPay Method.caption')}
      iconLink="/sber-pay.svg"
      iconWrapperColor="var(--paymentMethodCard-sbpMethodColor)"
      disabled={disabled}
    >
      {currStepForm[currStep]}
    </PaymentMethodCard>
  )
}
