import cn from 'classnames'
import { DetailedHTMLProps, FC, HTMLAttributes, PropsWithChildren } from 'react'
// local libs
import { Text } from 'src/shared/ui'
import { store } from 'src/app/store'
// styles
import styles from './PaymentMethodCard.module.scss'
// types
import { PaymentMethodCardProps } from './types'
import { TextKind, TextSizes } from '../Text'

export const PaymentMethodCard: FC<
  PropsWithChildren<PaymentMethodCardProps>
> = ({
  id,
  iconLink,
  iconWrapperColor,
  title,
  caption,
  children,
  disabled,
}) => {
  const toggleOpen = () => {
    if (disabled) return

    if (store.isPaymentMethodsDisabled.value) return

    if (store.paymentData.value?.availablePaymentMethods.length === 1) {
      store.activePaymentMethod.value = id
      return
    }

    if (store.activePaymentMethod.value !== id)
      store.activePaymentMethod.value = id
    else store.activePaymentMethod.value = null
  }

  const handleKeyPress = (
    e: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  ) => {
    if (e.key === 'Enter' || e.key === ' ') toggleOpen()
  }

  return (
    <div
      className={cn(styles.paymentMethodCardContainer, {
        [styles.paymentMethodCardContainerDisabled]: disabled,
      })}
    >
      <div
        className={cn(styles.paymentMethodCardHead, {
          [styles.paymentMethodCardHeadDisabled]: disabled,
        })}
        onClick={toggleOpen}
        onKeyDown={handleKeyPress}
        tabIndex={!disabled ? 0 : undefined}
      >
        <div
          className={styles.paymentMethodCardIconWrapper}
          style={{ backgroundColor: iconWrapperColor }}
        >
          <img src={iconLink} />
        </div>
        <div className={styles.paymentMethodCardHeadInfo}>
          <Text
            as="div"
            className={styles.paymentMethodCardTitle}
            kind={TextKind.title}
            size={TextSizes.large}
          >
            {title}
          </Text>
          <Text
            as="div"
            className={styles.paymentMethodCardCaption}
            kind={TextKind.basic}
            size={TextSizes.medium}
          >
            {caption}
          </Text>
        </div>
      </div>

      <div
        className={cn(styles.paymentMethodCardContent, {
          [styles.paymentMethodCardContentOpen]:
            store.activePaymentMethod.value === id ||
            store.paymentData.value?.availablePaymentMethods.length === 1,
        })}
      >
        <div className={styles.paymentMethodCardForm}>{children}</div>
      </div>
    </div>
  )
}
