import { FC, useState } from 'react'
import QRCode from 'qrcode.react'
import { useNavigate } from 'react-router-dom'
// local libs
import { store } from 'src/app/store'
import { useTranslate } from 'src/shared/hooks/useTranslate'
import { Button, CopyButton, PaymentMethodCard, Text } from 'src/shared/ui'
import { paymentRequestSuccessMock } from 'src/shared/assets/mocks'
// styles
import styles from './SBPMethod.module.scss'
import sbpImg from './assets/sbp.png'
// types
import { TextKind, TextSizes } from 'src/shared/ui/Text'
import {
  MethodNameEnum,
  PaymentMethodProps,
} from 'src/app/Layout/PaymentMethods/types'
import { PagesEnum } from 'src/app/routes'

export const SBPMethodQr: FC<PaymentMethodProps> = ({ disabled }) => {
  const { translate } = useTranslate()
  const navigate = useNavigate()
  const [currStep, setCurrStep] = useState<'start' | 'transferred'>('start')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const amount = `${store.paymentData.value?.paymentInfo.amount} ${store.paymentData.value?.paymentInfo.currency}`
  // TODO: delete mock
  const cardNumber =
    store.paymentData.value?.paymentInfo.cardNumber ||
    '2345 6575 2346 4567 6789 2345 6575 2346 4567 6789 2345 6575 2346 4567 6789 2345 6575 2346 4567 6789'

  const currStepDisplay = {
    start: (
      <Button
        size="stretch"
        onClick={() => {
          store.isPaymentMethodsDisabled.value = true
          setCurrStep('transferred')
        }}
      >
        {translate('SBP Method.startPaymentButtonText', {
          amount: `${store.paymentData.value?.paymentInfo.amount} ${store.paymentData.value?.paymentInfo.currency}`,
        })}
      </Button>
    ),
    transferred: (
      <div className={styles.container}>
        <section>
          <Text
            className={styles.title}
            kind={TextKind.title}
            size={TextSizes.medium}
          >
            {translate('SBP Method.scanQrCaption')}
          </Text>
          <div className={styles.qrContainer}>
            <QRCode value={cardNumber || ''} level="H" />
            <img src={sbpImg} alt="sbp-img" />
          </div>
          <CopyButton
            size="stretch"
            text={translate('SBP Method.copyNumberButtonText')}
            copyText={cardNumber || ''}
            textStyles={styles.qrCodeBtnText}
          />
        </section>
        <Button
          size="stretch"
          onClick={async () => {
            setIsSubmitting(true)
            //TODO: мок
            await paymentRequestSuccessMock()
            setIsSubmitting(false)
            navigate(PagesEnum.success)
          }}
          disabled={isSubmitting}
        >
          {translate('SBP Method.payedSuccessButtonText', {
            amount,
          })}
        </Button>
      </div>
    ),
  }

  return (
    <PaymentMethodCard
      id={MethodNameEnum.SBP}
      title={translate('SBP Method.titleQr')}
      caption={translate('SBP Method.captionQr')}
      iconLink="/sbp-qr.svg"
      iconWrapperColor="var(--paymentMethodCard-sbpMethodColor)"
      disabled={disabled}
    >
      {currStepDisplay[currStep]}
    </PaymentMethodCard>
  )
}
