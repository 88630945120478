import { FC } from 'react'
import { useForm } from 'react-hook-form'
import cn from 'classnames'
// local
import { store } from 'src/app/store'
import { savedCardResolver } from '../resolver'
import { CardCodeInput } from 'src/shared/ui/Input'
import { useTranslate } from 'src/shared/hooks/useTranslate'
import { Button } from 'src/shared/ui'
// types
import { BankCardFormEntities, BankCardFromData } from '../types'

import styles from '../BankCardMethod.module.scss'
import { useBankCardDeposit } from './utils'

export const SavedCardForm: FC = () => {
  const { translate } = useTranslate()
  const { makeBankCardDeposit } = useBankCardDeposit()

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<Pick<BankCardFromData, BankCardFormEntities.cvc>>({
    resolver: savedCardResolver,
  })

  const onSubmit = (
    formVals: Pick<BankCardFromData, BankCardFormEntities.cvc>,
  ) => {
    if (!store.selectedSavedCard.value) return
    makeBankCardDeposit({
      id: store.selectedSavedCard.value?.id,
      cvv: formVals[BankCardFormEntities.cvc],
    })
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={styles.bankCardMethodContainer}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={cn(styles.row, styles.w50)}>
        <CardCodeInput
          id={BankCardFormEntities.cvc}
          control={control}
          error={errors[BankCardFormEntities.cvc]?.message}
          label={translate('Bank Card Method.fieldCVCLabel')}
          placeholder={translate('Bank Card Method.fieldCVCPlaceholder')}
        />
      </div>
      <div className={styles.row}></div>
      <Button size="stretch" disabled={isSubmitting}>
        {translate('Bank Card Method.submitButton', {
          amount: `${store.paymentData.value?.paymentInfo.amount} ${store.paymentData.value?.paymentInfo.currency}`,
        })}
      </Button>
    </form>
  )
}
