export enum OrderStatusesEnum {
  NEW = 'NEW',
  ERROR = 'ERROR',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  EXPIRED = 'EXPIRED',
  CANCELED = 'CANCELED',
  CONFIRMED = 'CONFIRMED',
  DECLINED = 'DECLINED',
  PENDING = 'PENDING',
}
