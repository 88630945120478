import { FC, useState } from 'react'
import cn from 'classnames'
// local libs
import { useTranslate } from 'src/shared/hooks/useTranslate'
import { store } from 'src/app/store'
import { Button, CopyButton, PaymentMethodCard, Text } from 'src/shared/ui'
// styles
import styles from '../P2PMethod.module.scss'
// types
import { TextKind, TextSizes } from 'src/shared/ui/Text'
import {
  MethodNameEnum,
  PaymentMethodProps,
} from 'src/app/Layout/PaymentMethods/types'

export const P2P_H2H: FC<PaymentMethodProps> = ({ disabled }) => {
  const { translate } = useTranslate()
  const [isPaymentStart, setIsPaymentStart] = useState<boolean>(false)
  // const navigate = useNavigate()
  // const [isSubmitting, setIsSubmitting] = useState(false)

  const amount = `${store.paymentData.value?.paymentInfo.amount} ${store.paymentData.value?.paymentInfo.currency}`
  const cardNumber = store.paymentData.value?.paymentInfo.cardNumber

  const onPaymentStart = () => {
    store.activePaymentMethod.value = MethodNameEnum.P2P
    store.isPaymentMethodsDisabled.value = true
    setIsPaymentStart(!isPaymentStart)
  }

  return (
    <PaymentMethodCard
      id={MethodNameEnum.P2P}
      title={translate('P2P Method.title')}
      caption={translate('P2P Method.caption')}
      iconLink="/payment-p2p-fallback-icon.svg"
      iconWrapperColor="var(--paymentMethodCard-p2pMethodColor)"
      disabled={disabled}
    >
      <div className={styles.p2pMethodContainer}>
        {!isPaymentStart && (
          <Button size="stretch" onClick={onPaymentStart}>
            {translate('P2P Method.startButton', {
              amount,
            })}
          </Button>
        )}
        <div
          className={cn(styles.p2pMethodContent, {
            [styles.p2pMethodContentOpen]: isPaymentStart,
          })}
        >
          <div className={styles.p2pMethodStep}>
            <Text
              className={styles.p2pMethodStepTitle}
              kind={TextKind.title}
              size={TextSizes.medium}
            >
              {translate('P2P Method.withoutBankFirstStepTitle')}
            </Text>
            <CopyButton
              size="stretch"
              text={translate('P2P Method.copyNumberButton')}
              copyText={cardNumber || ''}
            />
            <Text
              className={styles.p2pMethodStepAnnotation}
              size={TextSizes.small}
            >
              {translate('P2P Method.withoutBankFirstStepAnnotation')}
            </Text>
          </div>

          <div className={styles.p2pMethodStep}>
            <Text
              className={styles.p2pMethodStepTitle}
              kind={TextKind.title}
              size={TextSizes.medium}
            >
              {translate('P2P Method.withoutBankSecondStepTitle')}
            </Text>
            <CopyButton
              size="stretch"
              text={translate('P2P Method.copyAmountButton')}
              copyText={amount}
            />
            <Text
              className={styles.p2pMethodStepAnnotation}
              size={TextSizes.small}
            >
              {translate('P2P Method.withoutBankSecondStepAnnotation')}
            </Text>
          </div>

          <Button
            size="stretch"
            disabled={true}
            // disabled={isSubmitting}
            // onClick={async () => {
            //   setIsSubmitting(true)
            //   setIsSubmitting(false)
            //   navigate(PagesEnum.success)
            // }}
          >
            {translate('P2P Method.confirmSuccessButton', {
              amount,
            })}
          </Button>
        </div>
      </div>
    </PaymentMethodCard>
  )
}
