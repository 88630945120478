import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
// local libs
import { DEFAULT_LANG_CODE, PRODUCT_NAME } from '../consts'
import { store } from 'src/app/store'
import {
  fallbackLanguages,
  getFallbackResources,
  getLanguages,
  getResources,
} from './utils'
import { effect } from '@preact/signals-react'
import { extendAxiosHeaders, getLangCode } from 'src/shared/utils'
import { Resources } from './types'
import { userSession } from '../api'

const defaultOptions = {
  debug: false,
  fallbackLng: DEFAULT_LANG_CODE,
  ns: PRODUCT_NAME,
  react: { useSuspense: false },
  interpolation: { escapeValue: false },
}
const languageDetector = new LanguageDetector()

const detectedLanguage = languageDetector.detect()
const languageCode = detectedLanguage
  ? (detectedLanguage as string).split('-')[0]
  : DEFAULT_LANG_CODE
const lng = getLangCode(languageCode)

effect(() => {
  extendAxiosHeaders({ 'App-Language-Code': store.language.value })
})

export const initI18n = async () => {
  let supportedLngs: Array<string>
  let resources: Resources
  store.language.value = lng

  try {
    if (!userSession) throw new Error()
    const recivedLanguages = await getLanguages()
    const recivedResources = await getResources()

    const isDefaultLanguageSelected = lng === DEFAULT_LANG_CODE
    const isDefaultLanguageAvailable = DEFAULT_LANG_CODE in recivedResources

    if (isDefaultLanguageSelected && !isDefaultLanguageAvailable) {
      const fallbackResources = getFallbackResources()
      const languages = [...recivedLanguages, ...fallbackLanguages]
      supportedLngs = languages.map(({ code }) => code)
      resources = { ...fallbackResources, ...recivedResources }
      store.languageList.value = languages
    } else {
      resources = recivedResources
      supportedLngs = recivedLanguages.map(({ code }) => code)
      store.languageList.value = recivedLanguages
    }
  } catch {
    resources = getFallbackResources()
    supportedLngs = [DEFAULT_LANG_CODE]
    store.languageList.value = fallbackLanguages
  }

  i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      resources,
      supportedLngs,
      lng,
      ...defaultOptions,
    })
}

export default i18n
